// section staff theme

.section__reviews {
  padding-bottom: 50px;
  @include adaptive(1279) {
    padding-bottom: 0;
  }
  
  &__grid {
    max-width: 100% !important;
    padding-left: 80px;
    @include adaptive(1279) {
      padding-left: 20px;
    }
    @include adaptive(767) {
      padding-right: 20px;
    }
  }
  
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 150px;
    @include adaptive(1023) {
      gap: 100px;
    }
    @include adaptive(767) {
      flex-wrap: wrap;
      gap: 50px;
    }
    
    > div {
      &:nth-of-type(1) {
        width: 100%;
        max-width: 420px;
        @include adaptive(1279) {
          max-width: 400px;
        }
        @include adaptive(1023) {
          max-width: 300px;
        }
        @include adaptive(767) {
          max-width: 100%;
        }
      }
      &:nth-of-type(2) {
        flex: 1;
        min-width: 1px;
        max-width: 100%;
      }
    }
  }
  
  &__desc {
    margin-top: 80px;
    margin-bottom: 80px;
    @include adaptive(1023) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  
  &__carousel {
    flex: 1;
    padding-bottom: 50px;
    
    .swiper {
      &-slide {
        display: flex;
        flex-direction: column;
        width: 400px !important;
        height: auto !important;
        @include adaptive(1023) {
          width: 300px !important;
        }
        @include adaptive(767) {
          width: calc(100% - 60px) !important;
        }
      }
    }
  }
  
  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    
    padding: 70px 40px;
    border-width: 10px;
    border-color: rgb(197, 222, 189);
    border-style: solid;
    @include adaptive(1023) {
      padding: 50px 20px;
      border-width: 5px;
    }
    
    &-quote {
      position: absolute;
      top: 50px;
      left: 20px;
      width: 100px;
      height: 90px;
    }
    
    p {
      z-index: 2;
      position: relative;
      font-size: 28px;
      font-family: "playfair-display",serif;
      color: rgb(151, 151, 151);
      line-height: 1.667;
      @include adaptive(1023) {
        font-size: 22px;
      }
      @include adaptive(767) {
        font-size: 18px;
      }
    }
  }
  
  &__navigation {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30%;
      @include adaptive(767) {
        justify-content: center;
        padding-right: 0;
      }
      
      a {
        & + a {
          margin-left: 50px;
        }
      }
    }
    
    img {
      width: auto;
    }
  }
}