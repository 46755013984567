/* banner module: theme */   

.bg-cover-photo {
  background-color: $color-white;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-tinted,
.bg-tinted-less,
.bg-tinted-more {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: rgba($color-black, 0.3);
  }            
}
.bg-tinted-less {
  &:after {
    background: rgba($color-black, 0.2);
  }
}
.bg-tinted-more {
  &:after {
    background: rgba($color-black, 0.8);
  }
}