/* footer module: theme */

.section__footer {
	background-color: $color-gray-4;
	
	&__grid {
		display: flex;
		flex-direction: column;
		align-items: center;
    padding: 140*$pxToRem 4rem 110*$pxToRem;
    @include media-mobile-only {
      padding: 100*$pxToRem 4rem 100*$pxToRem;
    }
	}
	
	.menu {
		width: 100%;
		margin-bottom: 4rem;
		
		ul {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;
      @include media-mobile-only {
        flex-direction: column;
      }
			
			li {
				margin: 0 3%;
				list-style: none;
				display: inline-block;
        @include media-below-laptop {
          margin: 0 2%;
        }
        @include media-below-desktop {
          margin: 0 1.5%;
        }
				@include media-mobile-only {
					display: block;
					line-height: 1.8;
				}
				
				a {
					font-family: "neue-haas-grotesk-display", sans-serif;
					font-size: 18px;
					color: rgb(229, 229, 229);
					text-transform: uppercase;
					line-height: 1.5;
					letter-spacing: 2px;
          @include media-below-laptop {
            letter-spacing: 0;
          }
          @include media-below-desktop {
            font-size: 18px;
          }
          @include media-mobile-only {
            line-height: 3;
          }
					
					transition: all 0.35s ease-in-out;
					
					&:hover {
						color: #daa520;
						transition: all 0.35s ease-in-out;
					}
				}
			}
		}
	}
	
	.blurb {
		max-width: 800px;
		line-height: 1.6;
	}
	
	.info {
		margin-top: 0;
		line-height: 1.6;
	}
	
	.social {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1.5rem;
		
		a {
			display: flex;
			align-items: center;
			margin: 0 0.5rem;
			
			svg {
				fill: $color-white;
				
				&.facebook {
					width: 1rem;
					margin-bottom: 0.1rem;
				}
				
				&.instagram {
					width: 1.5rem;
				}
				
				&.google {
					width: 1.5rem;
					height: 1.5rem;
				}
				
				transition: all $transition-default;
			}
			
			&:hover {
				svg {
					fill: $color-accent;
				}
			}
		}
	}
	
	a {
		font-weight: 400;
		
		&:hover {
			font-weight: 400;
		}
	}
}

.inner {
	.margin-top-250 {
		margin-top: 7.021vw;
	}
}