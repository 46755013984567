// section staff theme

.section__life {
	&__grid {
		max-width: 100% !important;
	}
	
	&__wrapper {
		align-items: center;
		@include adaptive(1279) {
			display: flex;
			flex-wrap: wrap;
		}
		
		> div {
			position: relative;
			
			&:nth-of-type(1) {
				@include adaptive(1279) {
					flex: 1;
					max-width: 100%;
				}
				@include adaptive(767) {
					flex: unset;
					width: 100%;
				}
			}
			&:nth-of-type(2) {
				@include adaptive(1279) {
					flex: 1;
					max-width: 100%;
				}
				@include adaptive(767) {
					flex: unset;
					width: 100%;
				}
				
				p {
					position: absolute;
					right: 30px;
					bottom: 30px;
					font-size: 38px;
					font-family: "playfair-display",serif;
					color: rgb(255, 255, 255);
					font-style: italic;
					line-height: 1.2;
					@include adaptive(767) {
						font-size: 30px;
					}
				}
			}
			&:nth-of-type(3) {
				padding: 120px;
				@include adaptive(1535) {
					padding: 100px 60px;
				}
				@include adaptive(1279) {
					width: 100%;
					padding: 0 20px 60px;
				}
				@include adaptive(767) {
					padding-top: 40px;
				}
				
				p {
					color: #666f77;
				}
			}
		}
	}
	
	&__content {
		display: flex;
		min-height: 100%;
		transform: translateY(-20%);
		padding: 45px;
		background-color: #c5debd;
		@include adaptive(1535) {
			transform: translateY(-10%);
		}
		@include adaptive(1535) {
			padding: 40px;
		}
		@include adaptive(1023) {
			padding: 20px;
		}
		@include adaptive(767) {
		transform: unset;
		}
		
		> div {
			padding: 125px 55px;
			border: 1px solid #fff;
			@include adaptive(1535) {
				padding: 100px 40px;
			}
			@include adaptive(1023) {
				padding: 40px 20px;
			}
		}
		
		p {
			color: #687165;
		}
	}
}