// section staff theme

.section__result {
  padding-top: 200px;
  padding-bottom: 115px;
  @include adaptive(767) {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  
  &__grid {
    position: relative;
    overflow: hidden;
    background-color: #000000;
  }
  
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right center;
  }
 
  &__wrapper {
    z-index: 2;
    position: relative;
    display: flex;
    padding: 34px;
    @include adaptive(767) {
      padding: 20px;
    }
    
    > div {
      width: 100%;
      min-height: 100%;
      padding: 175px 125px;
      border: 1px solid rgba($color-white, 0.5);
      @include adaptive(1535) {
        padding: 150px 100px;
      }
      @include adaptive(1365) {
        padding: 125px 100px;
      }
      @include adaptive(767) {
        padding: 50px 20px 200px;
      }
    }
  }
  
  &__content {
    width: 100%;
    max-width: 550px;
    @include adaptive(767) {
      max-width: 100%;
    }
  }
  
  &__logo {
    position: absolute;
    bottom: 60px;
    right: 60px;
    width: auto;
  }
  
  &__desc {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 20px;
    @include adaptive(767) {
      font-size: 18px;
      line-height: 1.8;
    }
  }
}