// section welcome theme

.section__welcome {
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  @include media-below-desktop { 
      background-image: none!important;
  }
  .left {
    h2 {
      span {
        font-size: 16*$pxToRem;
      }
    }
  }
  .right {
    img {
      max-width: 700px;
      padding: 5rem 0 10rem;
      @include media-below-desktop {
        padding: 2rem 0;
      }
    }
  }
}