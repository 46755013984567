// section dental theme

.section__dental {
  .grid-2-col-7-5 {
    @include media-below-desktop {
      grid-template-columns: 1fr;
    }
  }
  .left {
    @include media-below-desktop {
      order: 2;
    }
  }
  .right {
    @include media-below-desktop {
      padding: 0 $padding-default-vw 3rem!important;
    }
    
    p {
      margin-top: 75px;
      margin-bottom: 75px;
      color: #666f77;
      font-size: 20px;
      font-family: "neue-haas-grotesk-display",sans-serif;
      line-height: 1.667;
      letter-spacing: 0.05rem;    
      @include adaptive(767) {
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
  }
  
  &__link {
    position: absolute;
    right: 40px;
    bottom: 0;
    @include adaptive(1023) {
        position: relative;
        right: 0;
    }  
    
    a {
      font-size: 18px;
      font-family: "neue-haas-grotesk-display",sans-serif;
      color: rgb(0, 0, 0);
      text-transform: uppercase;
      line-height: 1.2;
      font-weight: 500;
      transition: all 0.35s ease-in-out;
      
      &:hover {
        color: #daa520;
        transition: all 0.35s ease-in-out;
      }
    }
  }
  
  .menu__procs {
    padding-left: 20px;
    
    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include adaptive(767) {
        grid-template-columns: repeat(1, 1fr);
      }
      
      li {
        display: flex;
        align-items: flex-start;
      }
      
      a {
        position: relative;
        font-size: 16px;
        font-family: "neue-haas-grotesk-display",sans-serif;
        color: rgb(0, 0, 0);
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2;
        
        &:hover {
          color: rgb(0, 0, 0);
          
          &:after {
            width: calc(100% + 30px);
          }
        }
        
        &:after {
          z-index: -1;
          right: auto;
          left: -20px;
          display: inline-block;
          top: -5px;
          width: 30px;
          height: calc(100% + 10px);
          border: none;
          background-color: #fed672;
        }
      }
    }
  }
}