// section schedule theme

.section__schedule {
  background-color: $color-gray-11;
  .grid-2-col-7-5 {
    @include media-mobile-only {
      padding: 3rem 2rem;
    }
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 7rem;
    @include media-mobile-only {
      justify-content: center;
      margin: 0;
    }
    @include media-tablet-only {
      margin-right: 3rem;
    }
    @include media-laptop-only {
      margin-right: 4rem;
    }
    p {
      font-size:26*$pxToRem;
      line-height: 1;
      @include media-mobile-only {
        font-size: 24*$pxToRem!important;
        text-align: center;
      }
      @include media-below-desktop {
        font-size: 20*$pxToRem;
      }
    }
  }
  .right {
    .btn {
      background-color: transparent;
      border: 1px solid $color-black;
      color: $color-black;
      &:hover {
        background-color: $color-accent;
        border-color: $color-accent;
        color: $color-white;
      }
      @include media-mobile-only {
        margin: 2rem auto 0;
      }
    }
  }
}