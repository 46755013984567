
// section staff theme

.section__partners {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include adaptive(767) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-left: 10px;
      padding-right: 10px;
    }
    
    a {
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      transition: all 0.35s ease-in-out;
      @include adaptive(767) {
        padding-left: 10px;
        padding-right: 10px;
      }
      
      &:hover {
        opacity: 0.55;
        transition: all 0.35s ease-in-out;
      }
    }
    
    img {
      width: auto;
      filter: opacity(0.65);
    }
  }
}