// section staff theme

.section__about {
  position: relative;
  padding-top: 150px;
  padding-bottom: 100px;
  //background-image: url(#{$imagesPath}img-about-illustration@2x.png);
  //background-repeat: no-repeat;
  //background-position: left center;
  //background-size: cover;
  @include adaptive(767) {
    padding-top: 100px;
    padding-bottom: 70px;
  } 
  
  &__illustration {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    max-width: unset;
    height: calc(100% + 150px);
    object-fit: cover;
    object-position: center;
  }
  
  &__wrapper {
    z-index: 2;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  &__label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgb(100, 148, 84);
    }
    
    h3 {
      z-index: 2;
      position: relative;
      padding-left: 20px;
      padding-right: 20px;
      background-color: #ffffff;
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      font-family: "neue-haas-grotesk-display",sans-serif;
      color: rgb(100, 148, 84);
      text-transform: uppercase;
      line-height: 1.667;
      letter-spacing: 0.1rem;
      @include adaptive(767) {
        font-size: 16px;
      }
    }
  }
  
  &__desc {
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-family: "neue-haas-grotesk-display",sans-serif;
    color: rgb(102, 111, 119);
    line-height: 1.667;
    text-align: center;
    letter-spacing: 0.05rem;
  }
}