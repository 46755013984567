// section staff theme

.section__insurance {
	padding-top: 150px;
	padding-bottom: 300px;
	@include adaptive(767) {
		padding-top: 100px;
		padding-bottom: 0;
	}
	
	&__grid {
		max-width: 100% !important;
	}
	
	&__visual {
		position: relative;
		display: block;
		width: 100%;
		max-width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
	
	&__block {
		position: relative;
		display: flex;
		padding: 65px 60px;
		background-color: $color-accent-light;
		@include adaptive(1279) {
			padding: 45px 30px;
		}
		@include adaptive(767) {
			padding: 30px 20px;
		}
		
		& + div {
			transform: translateY(160px);
			@include adaptive(767) {
				transform: unset;
			}
		}
		
		> div {
			width: 100%;
			padding: 155px 130px;
			border: 1px solid $color-white;
			@include adaptive(1535) {
				padding: 125px 100px;
			}
			@include adaptive(1365) {
				padding: 100px 75px;
			}
			@include adaptive(1279) {
				padding: 75px 50px;
			}
			@include adaptive(767) {
				padding: 50px 20px;
			}
		}
	}
	
	&__desc {
		color: #687165;
	}
	
	&__subtitle {
		font-size: 22px;
		font-weight: 700;
		font-family: "neue-haas-grotesk-display",sans-serif;
		color: rgb(104, 113, 101);
		line-height: 1.667;
		text-align: left;
	}
	
	&__list {
		li {
			color: #687165;
			font-size: 1.125rem;
			font-weight: 400;
			line-height: 2;
		}
	}
	
	&__btn {}
}