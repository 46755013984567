.inner {
	.section__banner {
		@include media-mobile-only {
			height: 400px !important;
		}
        
	}
	
	h1 {
		margin-bottom: 0 !important;
        font-family: "playfair-display",serif;
        font-size: 2.5rem;
        @include media-mobile-only {
			font-size: 2rem;
		}
	}
	
	h2, h3 {
		margin-bottom: 2rem !important;
		
		&.mb-0 {
			margin-bottom: 0 !important;
		}
        a{
            font-weight: 400;
            color: $color-black;
        }
	}
	
	p, ul {
		margin-top: 0 !important;
		@include media-mobile-only {
			font-size: 1rem;
			line-height: 2rem;
		}
	}
	
	.subtitle {
		margin-top: 0.3rem !important;
		text-transform: uppercase;
		letter-spacing: 0.3rem;
		font-size: 1rem;
		color: #777;
		padding-bottom: 1rem;
	}
}

.book-online {
	a {
		background: $color-accent !important;
		border: none !important;
		color: #fff !important;
		
		&:hover {
			border: none !important;
			background: #000;
		}
	}
}

.order-2-sm {
	order: -1;
	@include media-mobile-only {
		order: 2;
	}
}

.pt-1 {
	padding-top: 1rem;
}

.pt-5 {
	padding-top: 5rem;
	@include media-mobile-only {
		padding-top: 3.5rem;
	}
}

.pt-10 {
	padding-top: 10rem;
	@include media-mobile-only {
		padding-top: 7rem;
	}
}

.pt-12 {
	padding-top: 12rem;
	@include media-mobile-only {
		padding-top: 7rem;
	}
}

.pt-15 {
	padding-top: 15rem;
	@include media-mobile-only {
		padding-top: 7rem;
	}
}

.pb-2 {
	padding-bottom: 2rem;
	@include media-mobile-only {
		padding-bottom: 1rem;
	}
}

.pb-1 {
	padding-bottom: 1rem;
}

.pb-5 {
	padding-bottom: 10rem;
}

.pb-10 {
	padding-bottom: 10rem;
}

.plr-0 {
	padding-left: 0;
	padding-right: 0;
    @include media-below-laptop {
		padding-left: 3.9583333333vw;
	    padding-right: 3.9583333333vw;
	}
}

.plr-1 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.hero--animation {
	overflow: hidden;
	
	&:before {
		animation: heroBGAnimation 1.8s ease-in-out;
		transition-delay: .1s;
		content: "";
		display: block;
		background: var(--bg-image) center top no-repeat;
		background-size: cover;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: 0;
	}
}

@keyframes heroBGAnimation {
	0% {
		opacity: .1;
		transform: scale(1.2)
	}
	to {
		opacity: 1;
		transform: scale(1)
	}
}

.styled-boxes {
	background-size: cover !important;
	background-attachment: fixed;
	padding: 4rem 0 6rem;
	
	.grid-2-col {
		grid-gap: 60px;
	}
	
	h3 {
		font-size: 1.25rem;
		letter-spacing: 0.1rem;
		line-height: 1.5;
		position: relative;
		
		&:after {
			content: "";
			position: absolute;
			background: $color-accent;
			width: 100px;
			height: 3px;
			bottom: -15px;
			left: 0;
			display: block;
			transition: all .3s ease-in;
		}
		
	}
	
	.bg-cover-photo {
        border:3px solid $color-accent;
		@include media-mobile-only {
			padding: 0 1rem;
			background-color: transparent !important;
		}
	}
	
	.box {
		padding: 40px;
		background: $color-white;
		
		&:hover {
			h3 {
				&:after {
					width: 250px;
				}
			}
		}
	}
}

.testimonial {
	.padding-section-content {
		column-count: 3;
		column-gap: 100px;
		@include media-mobile-only {
			column-count: 1;
		}
	}
	
	&__wrap {
		padding-bottom: 6rem;
		margin-bottom: 10px;
		break-inside: avoid;
		
		&:after {
			content: "";
			width: 10%;
			height: 2px;
			position: relative;
			bottom: -2rem;
			background-color: $color-accent;
			display: block;
		}
	}
	
	&__rating {
		display: flex;
		max-width: 100px;
		max-width: 110px;
		gap: 6px;
		padding-bottom: 24px;
        svg{
            fill: $color-accent-2;
        }
	}
}

.case {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;
	
	&__wrap {
		padding-bottom: 4rem;
		@include media-mobile-only {
			padding: 2rem 1rem 0;
		}
		
		.title {
			text-align: center;
			font-size: 1.2rem;
			@include media-mobile-only {
				font-size: 1.25rem;
				margin-bottom: 1.25rem !important;
			}
		}
	}
}

.gap {
	column-gap: 1.5rem;
}

.gap-2 {
	column-gap: 2.5rem;
}

.gap-3 {
	column-gap: 3.5rem;
}

.gap-4 {
	column-gap: 4.5rem;
}

.gap-5 {
	column-gap: 5.5rem;
}

.insurance {
	&__logos {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		column-gap: 5rem;
		@include media-mobile-only {
			display: grid;
			grid-template-columns: repeat(3, 1fr) !important;
			column-gap: 3rem;
		}
		
		img {
			height: 80px;
			width: 100%;
			object-fit: contain;
		}
	}
}

.box-1 {
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
	padding: 3rem 4rem;
	@include media-mobile-only {
		padding: 1rem;
	}
	margin-bottom: 2rem;
}

.d-flex {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.proc-cta {
	display: flex;
	flex-direction: row;
	@include media-mobile-only {
		flex-direction: column;
	}
	
	a {
		margin-bottom: 1rem;
	}
}

.membership {
	&__bullet {
		svg {
			max-height: 80px;
			width: 100%;
			flex: 2;
			fill: $color-accent;
		}
		
		span {
			flex: 4;
			font-weight: 600;
		}
	}
}

.bg-dark {
	background-color: #111;
}

.title-sm {
	font-size: 1.5rem;
}

.swiper2 {
	width: 100%;
	list-style: none;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	padding: 0;
	position: relative;
	z-index: 1;
}

.mt-2 {
	margin-top: 2rem !important;
}

.mt-6 {
	margin-top: 6rem;
	@include media-mobile-only {
		margin-top: 1rem;
	}
}

.mb-15 {
	margin-bottom: 15rem;
	@include media-mobile-only {
		margin-bottom: 5rem;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-2 {
	margin-bottom: 2rem !important;
}

.mb-5 {
	margin-bottom: 5rem !important;
}

.contact-form {
	width: 100%;
}

.contact-form .captcha_main {
	position: relative !important;
}

.contact-form #captchaimg {
	position: absolute !important;
	right: 11px !important;
	top: 11px !important;
	max-width: 140px;
	width: 100%;
}

.contact-form .required_text {
	color: #999999 !important;
	float: left !important;
	font-size: 12px !important;
	font-style: italic !important;
	margin-top: 15px !important;
}

.contact-form input[type=text], .contact-form textarea, .contact-form select {
	background: none !important;
	border: none !important;
	color: #939494 !important;
	font: 16px/24px Arial, Helvetica, sans-serif !important;
	width: 100% !important;
	height: auto !important;
}

.contact-form .field {
	background: none repeat scroll 0 0 #fff !important;
	border: medium none !important;
	margin-bottom: 7px !important;
	height: auto !important;
}

.contact-form select {
	-moz-appearance: none !important;
	/* background: url("../images/select_arrow.png") no-repeat scroll 605px center #E9EAEA!important;*/
	text-indent: 0.01px !important;
	text-overflow: "" !important;
	width: 100% !important;
}

.contact-form .err {
	color: #fff !important;
}

.contact-form .err ul, .contact-form .err ul li {
	background: none;
}

.contact-form input[type="button"] {
	cursor: pointer !important;
	float: right !important;
	margin-top: 8px !important;
	padding: 14px 24px !important;
	box-sizing: content-box !important;
	background: $color-black !important;
	border: none !important;
	outline: none !important;
}

.contact-form input[type="button"]:hover {
	background: $color-accent-2 !important;
}

.contact-form.qc textarea {
	height: 85px !important;
}

.scrolled {
	#menu-trigger {
		//top: 1.6rem;
		//@include media-mobile-only {
		//	top: .8rem;
		//}
	}
}

.scrolled .mid .logo {
	//max-width: 70px !important;
}

.scrolled .header .header__content {
	background-color: hsla(0, 0%, 100%, 1);
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}

.menu-toggle {
	//align-items: flex-end;
}

.btn.outline {
	background: none;
	border: 1px solid #000;
}

.btn.outline:hover {
	background-color: #000;
	border: 1px solid #000;
}

@media (max-width: 992px) {
	.rellax {
		transform: translate3d(0, 0, 0) !important;
	}
	footer {
		font-size: 1rem;
	}
}

.hours {
	width: 100%;
	font-size: 0.9rem;
	
	td {
		padding: 5px 15px;
	}
	
	tr:nth-child(even) {
		background-color: #eee;
	}
}

table, th, td {
	border: none;
	border-collapse: collapse;
}

.contact-info {
	@media (max-width: 1200px) {
		grid-template-columns: 1fr !important;
	}
}

.styled-boxes {
	.bg-cover-photo {
		margin-bottom: 3rem;
	}
	
	.box {
		img {
			max-width: 200px;
			float: right;
			margin: -4rem 0 2rem 3rem;
			border:1px solid $color-accent;
			@include media-mobile-only {
				margin: -4rem auto 2rem;
				float: none;
			}
		}
	}
}

.reverse-on-mobile {
	div:first-of-type {
		@include media-mobile-only {
			order: 2;
		}
	}
	
	div:last-of-type {
		@include media-mobile-only {
			order: 1;
		}
	}
}

.inner-content {
	.align-center {
		@include media-mobile-only {
			text-align: left;
		}
		
		&.mobile {
			text-align: center !important;
		}
	}
}

.view-all {
	position: absolute;
	bottom: 60px;
	right: 70px;
	@include media-below-laptop {
		position: relative;
		bottom: inherit;
		right: inherit;
	}
	
	a {
		color: $color-black;
		font-size: 0.9rem;
		font-weight: 600;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
	}
}

body .uwy.userway_p3 .uai{
	bottom: 15px !important;
	left: calc(100vw - 20px) !important;
	@include media-mobile-only {
		bottom: 70px !important;
	}
}

.hide-on-desktop {
	display: none;
	@include media-mobile-only {
		display: flex;
	}
}

.hide-on-mobile {
	display: flex;
	@include media-mobile-only {
		display: none;
	}
}