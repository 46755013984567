/* button module: core */
// TODO: make more logical separation between core and theme styles

// button,
// input[type="button"],
// input[type="submit"] {
//   background-color: transparent;
// }

.btn,
.button-icon {
  background-color: $color-black;
  width: max-content;
  display: flex;
  text-transform: uppercase;
  position: relative;
  overflow: hidden; // clip box for sliding background
  padding: 9*$pxToRem 36*$pxToRem!important;
  min-height: 50*$pxToRem;
  //min-width: 120px;
  //padding-bottom: 7px;
  align-items: center;
  justify-content: center;
  z-index: 0;
  //font-size: $font-size-body-sm;
  font-weight: 600;
  font-size: 14px;
  font-family: "neue-haas-grotesk-display",sans-serif;
  color: #fff;
  letter-spacing: 1px;
  //border-radius: 10px;
  //border: 1px solid $color-gray-1;
  transition: all $transition-default;
  //@include media-mobile-only {
  //  min-height: auto;
  //}
  @include adaptive(767) {
    min-height: 45*$pxToRem;
    font-size: 15px;
  }
  
  
  &.green {
    background-color: $color-accent-light;
    color: #3a3a3a;
    
    &:hover {
      background-color: $color-black;
      border-color: $color-black;
    }
  }
  &.hover-orange {
    &:hover {
      background-color: #DAA520;
      border-color: #DAA520;
      color: #000000 !important;
    }
  }
  
  &:hover {
    background-color: $color-accent-2;
    color: $color-white!important;
    border-color: $color-accent-2;
  }
}

.button {
  // Plus sign preceding button text
  //&::before {
  //  content: "+";
  //  padding-right: 4*$pxToRem;
  //}
}

.padding-section-content .button:last-child {
  @include media-mobile-only {
    margin-bottom: $padding-mobile;
  }
}

.button-icon {
  svg {
    display: block;
    width: 16*$pxToRem;
    height: 16*$pxToRem;
    margin-right: 10*$pxToRem;
  }
}

.inverted a.btn,
.inverted a.button-icon,
a.btn.inverted,
a.button-icon.inverted {
  background-color: $color-accent;
  color: $color-gray-12;
  transition: all $transition-default;
  &:hover {
    background-color: $color-black;
    color: $color-white;
  }
}

a.svg, .svg {
  position: relative;
  &.inverted {
    svg {
      stroke: $color-white;
    }
  }
  svg {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    right: 0;
    bottom: 0;
    pointer-events: none;
    fill: none;
    stroke: $color-gray-11;
    stroke-width: 0.5px;
    margin: 0 auto;
    transform: translateX(-50%);
    path {
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
      transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);

    }
  }
  &:hover {
    svg {
      path {
        stroke-dashoffset: 0;
        transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
        transition-duration: 0.4s;
      }
    }
  }
}

.big-button {
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid $color-black;
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  transition: all $transition-default;
  svg {
    fill: $color-black;
    transition: all $transition-default;
  }
  &:hover {
    //background-color: $color-accent;
    //border-color: $color-accent;
    svg {
      fill: $color-white;
    }
    &:after {
      top: 0;
      opacity: 1;
    }
  }
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    border-radius: 50%;
    background-color: $color-accent;
    transition: all 0.4s ease-in;
  }
}