// section staff theme

.section__touch {
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #e1e1e1;
    
  @include adaptive(1023) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    @include adaptive(1279) {
      flex-direction: column;
      text-align: center;
    }
    
    h2 {
      margin-bottom: 0!important;    
      & + a {
        margin-left: 80px;
        @include adaptive(1279) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
}