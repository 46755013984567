// dependencies imports
@import '_modules/core/_utils';
@import '_modules/core/_media';
@import '_modules/theme/_config';

@import '_modules/theme/typography';

// core module imports
@import '_modules/core/form';

// theme module imports
@import '_modules/theme/form';
@import '_modules/theme/swiper-custom';

@import '_modules/core/align';
@import '_modules/core/base';
@import '_modules/core/bg';
@import '_modules/core/container';
@import '_modules/core/gutter';
@import '_modules/core/grid';
@import '_modules/core/inverted';
@import '_modules/core/margin';
@import '_modules/core/padding';
@import '_modules/core/section';
@import '_modules/core/sr-only';
@import '_modules/core/svg';
@import '_modules/core/width';

// theme module imports
@import '_modules/theme/base';

@import '_modules/theme/aos';
@import '_modules/theme/accordion';
@import '_modules/theme/banner';
@import '_modules/theme/bg';
@import '_modules/theme/bio';
@import '_modules/theme/button';
@import '_modules/theme/column';
@import '_modules/theme/container';
@import '_modules/theme/footer';
@import '_modules/theme/header';
@import '_modules/theme/hr';
@import '_modules/theme/img';
@import '_modules/theme/logo';
@import '_modules/theme/margin';
@import '_modules/theme/main-navigation';
@import '_modules/theme/padding';
@import '_modules/theme/photo-gallery-link';
@import '_modules/theme/quote';
@import '_modules/theme/read-more';
@import '_modules/theme/social';
@import '_modules/theme/svg';
@import '_modules/theme/width';
@import '_modules/theme/extras';

// home module imports
@import '_modules/home/home';

@include media-mobile-only {
	#wpadminbar {
		display: none;
	}
}

